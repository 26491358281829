import { Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMixpanel } from "react-mixpanel-browser";
import { useSelector } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import Calendar from "~/components/common/calendar/Calendar";
import TitleContainer from "~/components/common/titleContainer/TitleContainer";
import MuiThemeSelectScheduleTime from "./MuiStylesSelectScheduleTime";
import React from "react";
import ScheduleProgress from "~/components/common/scheduleProgress/ScheduleProgress";
import ErrorDialog from "~/components/common/dialog";
import businessService from "~/services/businessService";
import { useContext } from "react";
import { DialogBuilder } from "~/utils/DialogProvider";
import couponServices from "~/services/couponServices";
import WhatsAppButton from "~/components/common/whatsappButton/whatsappButton";

const SelectScheduleDate = (props) => {
	const { business } = useSelector((store) => store.business);
	const selectedServices = props?.location?.state?.services;
	const coupon = props?.location?.state?.coupon;
	const dialog = useContext(DialogBuilder);
	const { id } = useParams();
	const [openError, setOpenError] = useState(false);
	const [errorMessage, setErrorMessage] = useState(false);
	const [loading, setLoading] = useState(true);
	const [availableDatesCalendar, setAvailableDatesCalendar] = useState(null);

	const mixpanel = useMixpanel();
	const { t } = useTranslation();
	const { user } = useSelector((store) => store.auth);
	const classes = MuiThemeSelectScheduleTime();

	const [date, setDate] = useState(null);
	const [selectedDate, setSelectedDate] = useState(null);

	const showError = (message) => {
		setErrorMessage(message);
		setOpenError(true);
	};

	const getAvailableDates = () => {
		let serviceIds = [];
		if (selectedServices) {
			selectedServices.forEach((service) => {
				serviceIds.push(service.featureItems);
			});
			if (coupon) {
				couponServices
					.getAvailableDatesCoupon(business.id, serviceIds, coupon.coupon.coupon_id)
					.then((res) => {
						setLoading(false);
						if (res.data.length === 0) {
							dialog
								.withTitle(t("No dates or times available"))
								.withMessage(t("Please contact the establishment"))
								.withAction(() => props.history.goBack())
								.show();
							return;
						}
						setAvailableDatesCalendar(res.data);
					})
					.catch(({ response }) => {
						setLoading(false);
						dialog
							.withTitle(t("Error loading available dates"))
							.withMessage(response?.data?.message)
							.withAction(() => props.history.goBack())
							.show();
					});
			} else {
				businessService
					.getAvailableDates(business.id, serviceIds)
					.then((res) => {
						setLoading(false);
						if (res.data.length === 0) {
							dialog
								.withTitle(t("No dates or times available"))
								.withMessage(t("Please contact the establishment"))
								.withAction(() => props.history.goBack())
								.show();
							return;
						}
						setAvailableDatesCalendar(res.data);
					})
					.catch(({ response }) => {
						setLoading(false);
						dialog
							.withTitle(t("Error loading available dates"))
							.withMessage(response?.data?.message)
							.withAction(() => props.history.goBack())
							.show();
					});
			}
		}
	};

	useEffect(() => {
		getAvailableDates();
	}, []);

	const goToNextPage = (date) => {
		props.history.push(`/selecionar-horarios/${id}`, {
			date: date,
			services: selectedServices,
			bookingId: props?.location?.state?.bookingId,
			reservationUpdate: props?.location?.state?.reservationUpdate,
			// feature_items: [],
			coupon: coupon,
			reschedule: props?.location?.state?.reschedule,
		});
	};

	const handleOk = () => {
		setOpenError(false);
		props.history.goBack();
	};

	const handleDate = (newDate) => {
		goToNextPage(newDate);
	};

	return (
		<>
			<TitleContainer
				title={
					<Typography className={classes.selectTimeLabel}>{date ? t("Select Time") : t("Select Date")}</Typography>
				}
				handleClick={() => (!date ? props.history.goBack() : setDate(null))}
				loading={loading}>
				<ScheduleProgress progress={2}></ScheduleProgress>
				<div style={{ margin: "30px 10px" }}>
					<Calendar
						setDate={(date) => {
							setSelectedDate(date);
							mixpanel.identify(user?.phoneNumber ? user?.phoneNumber : user?.uid, {
								my_custom_prop: "foo",
							});
							mixpanel.track("web - selecionou data", {
								my_custom_prop: "foo",
							});
							setTimeout(() => {
								handleDate(date);
							}, 100);
						}}
						date={selectedDate}
						availableDates={availableDatesCalendar}></Calendar>
				</div>
				<ErrorDialog onOpen={openError} message={errorMessage} onOk={handleOk} title={t("Attention")} />
			</TitleContainer>
			{/* <WhatsAppButton  bottom={"30px"}/> */}
		</>
	);
};
export default withRouter(SelectScheduleDate);
