import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	title: {
		color: "#393762",
		fontSize: "4vh",
		lineHeight: "0.9",
		letterSpacing: "-0.66px",
	},
	subtitle: {
		color: "#393762",
		fontSize: 16,
	},
	field: { width: "95%", paddingBottom: "5%" },
	icon: {
		cursor: "pointer",
		color: "#474747",
	},
	message: {
		color: "#939393",
		fontSize: "80%",
		paddingBottom: 10,
	},
	divMessage: {
		width: "65%",
		display: "flex",
		textAlign: "center",
		justifyContent: "center",
	},
	button: {
		backgroundColor: "#393762",
		
		color: "#ffffff",
		width: "90%",
		height: 50,
		fontSize: 12,
		fontWeight: "normal",
		textTransform: "none",
		borderRadius: 20,
	},
	buttonAlterar: {
		backgroundColor: "transparent",
		color: "#3689ea",
		height: 18,
		fontSize: 14,
		fontWeight: "bold",
		textTransform: "none",
		paddingBottom: 10,
	},
	termos: {
		color: "#3689ea",
	},
	email: {
		fontWeight: "normal",
		textTransform: "none",
	},
	whatsApp: {
		borderWidth: 2, 
		borderColor: "#393762",
		backgroundColor: "transparent",
		color: "#393762",
		width: "300px",
	},
	sms: {
		backgroundColor: "#DCDCDC",
		color: "#ffffff",
		width: "300px",
		borderColor: "#D3D3D3",
	}
}));
