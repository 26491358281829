import authService from "~/services/authService";
import { Firebase } from "./firebase";
import { RecaptchaVerifier, signInWithPhoneNumber,
	createUserWithEmailAndPassword, sendEmailVerification,
	signInWithEmailAndPassword, sendPasswordResetEmail,
	signInWithCustomToken, } from "firebase/auth";
import api from "~/services/api";
import apiTwilio from "~/services/apiTwilio";

export const getUser = () => {
	return api.get('me');
};

export const sendNumber = (number) => {
	const auth = Firebase;
	if (!number.startsWith('+55') && !number.startsWith('55') && !number.startsWith('+351') && !number.startsWith('351')) {
		auth.languageCode = 'en';
	}
	
	window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
		'size': 'invisible',
		'callback': (response) => {
		}
	  }, auth);
	let appVerifier = window.recaptchaVerifier;
	return signInWithPhoneNumber(auth, number, appVerifier);
};

export const verifyCode = (getConfirmation, code) => {
	const credential = authService.getCredential(getConfirmation.verificationId, code);
	return authService.authenticateUser(credential);
};

export const sendEmailCreate = (email, password) => {
	const auth = Firebase;
	return createUserWithEmailAndPassword(auth, email, password);
};

export const sendEmailVerify = (user) => {
	return sendEmailVerification(user);
};

export const loginEmail = (email, password) => {
	const auth = Firebase;
	return signInWithEmailAndPassword(auth, email, password);
};

export const forgotPassword = (email) => {
	const auth = Firebase;
	return sendPasswordResetEmail(auth, email);
};

// Login com SMS Twilio

export const sendSmsTwilio = (celphone, isWhatsApp = false) => {
	if (isWhatsApp) {
		return apiTwilio.apiTwilio.post('user/auth/', {
			phone: celphone,
			channel: 'whatsapp',
		});
	}
	return apiTwilio.apiTwilio.post('user/auth/', {
		phone: celphone,
	});
};

export const sendSMSCodeTwilio = (celphone, codeTwilio) => {
	return apiTwilio.apiTwilio.post('user/auth/verify', {
		phone: celphone,
		code: codeTwilio,
	});
};

export const sendTokenFirebase = (token) => {
	const auth = Firebase;
	return  signInWithCustomToken(auth, token);
};

// Login com msg WhatsApp

export const sendMsgWhatsApp = (celphone) => {
	return apiTwilio.apiTwilio.post('user/auth/guru', {
		phone: celphone,
	});
};

export const sendMessageCodeWhatsApp = (celphone, codeWhatsApp) => {
	return apiTwilio.apiTwilio.post('user/auth/guru/verify', {
		phone: celphone,
		code: codeWhatsApp,
	});
};

// Login com wavy

export const sendMsgWavy = (celphone) => {
	return apiTwilio.apiTwilio.post('user/auth/wavy', {
		phone: celphone,
	});
};

export const sendMessageWavy = (celphone, codeWhatsApp) => {
	return apiTwilio.apiTwilio.post('user/auth/wavy/verify', {
		phone: celphone,
		code: codeWhatsApp,
	});
};