import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "~/store";
import "./assets/styles/index.scss";
import App from "./views/app/App";
import { ClearCacheProvider } from "react-clear-cache";
import "./i18n/index";

ReactDOM.render(
	<ClearCacheProvider duration={5000}>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</PersistGate>
		</Provider>
	</ClearCacheProvider>,
	document.getElementById("root")
);
