import { Card, CardContent, Divider, Grid, Typography } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import CustomCardHeader from "~/components/common/customCardHeader/CustomCardHeader";
import CustomTabs from "~/components/common/customTabs/CustomTabs";
import SpinnerLoading from "~/components/common/spinnerLoading/SpinnerLoading";
import TabPanel from "~/components/common/tabPanel/TabPanel";
import MuiStylesVendorPage from "~/components/vendorPage/MuiStylesVendorPage";
import VendorDescription from "~/components/vendorPage/vendorDescription/VendorDescription";
import Footer from "~/components/common/footerContainer/FooterContainer";
import TitleContainer from "~/components/common/titleContainer/TitleContainer";
import { useTranslation } from "react-i18next";
import { LanguageSwitcher } from "~/components/LanguageSwitcher";
import businessService from "~/services/businessService";
import { useContext } from "react";
import { DialogBuilder } from "~/utils/DialogProvider";
import businessAction from "~/store/actions/businessAction";
import googleReserveService from "~/services/googleReserveService";
import userUpdateService from "~/services/userUpdateService";
import VendorCategoryServices from "~/components/vendorPage/VendorCategoryServices/VendorCategoryServices";
import Utils from "~/helpers/Utils";
import { Firebase } from "~/services/firebase";
import { useMixpanel } from "react-mixpanel-browser";
import { ArrowBackIos } from "@material-ui/icons";

const VendorPage = (props) => {
	const mixpanel = useMixpanel();
	const dispatch = useDispatch();
	const classes = MuiStylesVendorPage();
	const { id, bookingTransactionId } = useParams();
	const { user } = useSelector((store) => store.auth);
	const [business, setBusiness] = useState(null);
	const [currentTabIndex, setCurrentTabIndex] = useState(0);
	const dialog = useContext(DialogBuilder);
	const { t } = useTranslation();
	
	const [showArrow, setShowArrow] = useState(false);
	const [loading, setLoading] = useState(true);

	const handleChangeTab = (event, newTabIndex) => {
		setCurrentTabIndex(newTabIndex);
	};

	const tabs = [
		{ value: 0, lazy: true, label: t("Serviços") },
		{ value: 1, lazy: true, label: t("Sobre") },
	];

	useEffect(() => {
		localStorage.removeItem("hideAppDownloadBar");
	}, []);

	const loadSectors = async (categories) => {
		try {
			const sectors = await businessService.getSectors(categories.map((item) => item.id));
			localStorage.setItem("@prit-web/sectors", JSON.stringify(sectors.data));
			setLoading(false);
		} catch (err) {
			/*dialog
				.withMessage(t("Unable to load the sectors of this establishment"))
				.withButtonText(t("Try again"))
				.withAction(() => {
					loadSectors(categories);
				})
				.show();*/
			setLoading(false);
		}
	};

	const loadBusiness = () => {
		setLoading(true);
		businessService
			.getBusiness(id)
			.then((res) => {
				setBusiness(res.data);
				dispatch(businessAction.setBusiness(res.data));
				loadSectors(res.data.categories);
			})
			.catch((err) => {
				dialog
					.withMessage(err?.response?.data?.message)
					.withButtonText(t("Try again"))
					.withAction(() => {
						loadBusiness();
					})
					.show();
				setLoading(false);
			});
	};

	useEffect(() => {
		let prit_qa = new URLSearchParams(props.location.search).get("prit-qa");
		if (
			prit_qa &&
			process.env.REACT_APP_FIREBASE_INTEGRATION_TESTING &&
			prit_qa === process.env.REACT_APP_FIREBASE_INTEGRATION_TESTING
		) {
			Firebase.settings.appVerificationDisabledForTesting = true;
		}

		if (id && user) {
			const me = JSON.parse(localStorage.getItem("@prit-web/meData"));
			let rwg_token = new URLSearchParams(props.location.search).get("rwg_token");
			if (rwg_token) {
				if (!user.isAnonymous) {
					if (!me?.rwg_token || me?.rwg_token !== rwg_token) {
						userUpdateService.pachUserUpdate(localStorage.getItem("@prit-web/userId"), {
							rwg_token: rwg_token,
						});
					}
					dispatch(googleReserveService.googleReservePost(rwg_token));
				} else {
					localStorage.setItem("@prit-web/rwg_token", rwg_token);
				}
			}
			if (bookingTransactionId) {
				props.history.push(`/reservas/${id}/bookingTransactionId/${bookingTransactionId}`);
			} else {
				loadBusiness();
				// dispatch(couponAction.getCouponBusiness(id));
			}
		}
	}, [user]);

	const handleScheduleClick = () => {
		localStorage.setItem("@prit-web/NoCoupon", false);
		mixpanel.identify(user?.phoneNumber ? user?.phoneNumber : user?.uid, {
			my_custom_prop: "foo",
		});

		mixpanel.people.set({ $name: user?.displayName });
		mixpanel.people.set({ $email: user?.email });
		mixpanel.people.set({ $phone: user?.phoneNumber });

		mixpanel.track("web - clicou agendar", {
			my_custom_prop: "foo",
		});

		if (business.sectorId !== process.env.REACT_APP_PET_SECTOR_ID) {
			props.history.push(`/selecionar-categoria/${business.id}`);
		} else {
			props.history.push(`/pets-cadastrados/${business.id}`);
		}
	};

	const handleVendorCategoryClick = () => {
		setShowArrow(true);
	};

	const footer = () => {
		return <Footer section="agendar" />;
	};

	return (
		<Fragment>
			<TitleContainer loading={loading} headerStyle={{ boxShadow: "0", padding: "0" }} footer={footer()}>
			<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
				{showArrow && (
					<ArrowBackIos onClick={() => window.location.reload()} />
				)}
				<div style={{ marginLeft: 'auto' }}>
					<LanguageSwitcher />
				</div>
			</div>

				<Helmet>
					<title>{`${business?.name ? business.name : "Business"} | Prit`}</title>
					<link rel="canonical" href={process.env.REACT_APP_URL + "/business/" + id} />
				</Helmet>
				<Grid container direction="column" style={{ height: "100%", minHeight: "100vh" }}>
					<SpinnerLoading loading={loading} />

					<Grid item className={classes.mainGrid}>
						<Card
							style={{
								minHeight: "100vh",
								boxShadow: "rgb(255 255 255 / 0%) 0px 2px 1px -1px",
							}}>
							<CustomCardHeader business={business} />
							<div style={{ padding: "8px", paddingBottom: "0" }}>
								{business?.address && (
									<>
										<Typography className={classes.descriptionTextAddress}>
											{`${Utils.capitalize(business.address.street)}, ${business.address.number} - ${Utils.capitalize(
												business.address.neighborhood
											)},  ${Utils.capitalize(business.address.city)} - ${business.address.state} - CEP ${
												business.address.postalCode
											}`}
										</Typography>
									</>
								)}
							</div>

							<CardContent style={{paddingBottom: '57px'}}>
								{/* <PurpleButton
									style={{
										backgroundColor: business?.status === "active" ? "#440067" : "#474747a8",
										cursor: "unset",
										padding: "7px",
										color: "#ffffff",
										height: 44,
										fontSize: "14px",
										display: "flex",
										flex: 1,
										width: "100%",
										borderRadius: "100px",
										marginBottom: "6%",
									}}
									variant="contained"
									onClick={handleScheduleClick}>
									{t("Book")}
								</PurpleButton> */}
								{/* <Divider /> */}
								<div style={{ justifyContent: "start" }}>
									<CustomTabs value={currentTabIndex} handleChange={handleChangeTab} tabs={tabs} />
								</div>
								<div style={{ height: "1px", display: "flex", backgroundColor: "gray" }}></div>
								<TabPanel value={currentTabIndex} index={0}>
									<div onClick={handleVendorCategoryClick}>
										<VendorCategoryServices business={business} />
									</div>
								</TabPanel>
								<TabPanel value={currentTabIndex} index={1}>
									<VendorDescription business={business} />
								</TabPanel>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</TitleContainer>
			{/* <WhatsAppButton bottom={"80px"} /> */}
		</Fragment>
	);
};

export default withRouter(VendorPage);