import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from "@material-ui/core";
import React, { useState } from "react";
import _ from "lodash";
import TitleDialog from "~/components/modalBookingScreen/TitleDialog";
import Cancel from "~/assets/icons/success-boooking-cancelation.png";
import DialogCancel from "~/components/common/dialog/dialogCancel";
import ErrorDialog from "~/components/common/dialog/index";
import bookingTransactionService from "~/services/bookingTransactionService";
import payPaymentService from "~/services/payPaymentService";
import useStyles from "./styles";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const DialogCancelConfirm = ({
	bookingId,
	date,
	dateEnd,
	serviceName,
	avatarProfessional,
	onOpen,
	onClose,
	setOpenCancel,
	price,
	paymentCancellation,
	bookingTransactionId,
	businessId,
	startTimeFormat,
	createdAt,
	idGfi,
}) => {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const [openErrorDados, setOpenErrorDados] = useState(false);
	const [openErrorCancel, setOpenErrorCancel] = useState(false);
	const [openErrorRefund, setOpenErrorRefund] = useState(false);
	const [cancelReservation, setCancelReservation] = useState(false);
	const [messageError, setMessageError] = useState("");

	const { business } = useSelector((store) => store.business);
	const { t } = useTranslation();

	const currency = business?.currency;

	const handleOk = () => {
		setOpenErrorDados(false);
		setOpenErrorCancel(false);
		setOpenErrorRefund(false);
	};

	const onCancel = async (reason) => {
		// call API and change booking state to CANCELED - receive booking as parameter
		try {
			const bookingCancel = await bookingTransactionService.putBookingStatus(bookingId, reason, "cancelled", "client");
			if (bookingCancel) {
				setOpen(!open);
			}
		} catch (error) {
			setOpenErrorCancel(true);
		}
	};

	const onRefund = async () => {
		// refund call
		try {
			let obj = {
				recipient_id: businessId,
				booking_transaction_id: bookingTransactionId,
				date_booking_created: createdAt,
				service_id: idGfi,
			};
			await payPaymentService.postPaymentRefund(obj);
			// cancel reservation if refund is ok
			onCancel();
		} catch (err) {
			let { message } = err;

			if (err.response) {
				if (err.response.data && err.response.data.message) {
					({ message } = err.response.data);
				}
			}

			setTimeout(() => {
				setOpenErrorRefund(true);
				setMessageError(message);
			}, 300);
		}
	};

	const handleClickCancelBooking = () => {
		setCancelReservation(true);
		if (!_.isNull(paymentCancellation)) {
			if (paymentCancellation.refund_available) {
				onRefund();
			} else {
				onCancel();
			}
		} else {
			setTimeout(() => {
				setOpenErrorDados(true);
			}, 300);
		}
	};

	return (
		<>
			<Dialog
				onClose={() => setOpenCancel(false)}
				open={onOpen}
				PaperProps={{
					style: { borderRadius: 20, maxHeight: 270, minWidth: 270 },
				}}>
				<DialogTitle style={{ padding: "16px 24px 0" }}>
					{<TitleDialog onClose={() => setOpenCancel(false)} message={t("Cancel appointment")} />}
				</DialogTitle>
				<DialogContent>
					<Divider />
					<Typography className={classes.hours}>
						{date.format("HH:mm")}-{dateEnd.format("HH:mm")}
					</Typography>
					<Typography className={classes.nameService}>{serviceName}</Typography>
					<img className={classes.avatar} src={avatarProfessional} alt="" />
					<Divider />
					<Typography className={classes.values}>{t("Total services")}</Typography>
					<Typography className={classes.type}>
						{parseFloat(price ? price / 100 : 0).toLocaleString("pt-BR", {
							style: "currency",
							currency: currency,
						})}
					</Typography>
					<Typography className={classes.values}>{t("Discount")}</Typography>
					<Typography className={classes.type}>
						{parseFloat(paymentCancellation?.discounts / 100).toLocaleString("pt-BR", {
							style: "currency",
							currency: currency,
						})}
					</Typography>
					<Typography className={classes.values}>{t("Payment advance")}</Typography>
					<Typography className={classes.type}>
						{parseFloat(paymentCancellation?.anticipated_payment / 100).toLocaleString("pt-BR", {
							style: "currency",
							currency: currency,
						})}
					</Typography>
					<Typography className={classes.values}>{t("cancellation fee")}</Typography>
					<Divider />
					<Typography className={classes.type}>
						{parseFloat(paymentCancellation?.cancellation_fee / 100).toLocaleString("pt-BR", {
							style: "currency",
							currency: currency,
						})}
					</Typography>
					<Typography className={classes.backValue}>{t("returned value")}</Typography>
					<Typography className={classes.type}>
						{parseFloat(paymentCancellation?.returned_amount / 100).toLocaleString("pt-BR", {
							style: "currency",
							currency: currency,
						})}
					</Typography>
				</DialogContent>

				<DialogActions className={classes.actions} style={{ backgroundColor: "#FF3B30" }}>
					<Button
						className={classes.button}
						style={{ color: "#ffffff" }}
						disabled={cancelReservation}
						onClick={() => handleClickCancelBooking()}>
						{t("Confirm Cancel")}
					</Button>
				</DialogActions>
			</Dialog>

			<DialogCancel
				onOpen={open}
				onClose={() => {
					setOpen(false);
					window.location = "/reservas/"+businessId;
				}}
				img={Cancel}
				message={t("Appointment cancelled!")}
				text={t("text cancel")}
			/>

			<ErrorDialog
				onOpen={openErrorDados}
				message={t("Failed to load cancellation data")}
				onOk={handleOk}
			/>

			<ErrorDialog
				onOpen={openErrorCancel}
				message={t("An error occurred while canceling")}
				onOk={handleOk}
			/>

			<ErrorDialog onOpen={openErrorRefund} message={`Erro na solicitação, ${messageError}`} onOk={handleOk} />
		</>
	);
};

export default DialogCancelConfirm;
