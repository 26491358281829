import Constants from "~/helpers/enums/Constants";
import api from "./api";

const googleReservePost = (rwg_token) => (dispatch) => {
	return api.post("/google_reserve/collect", { rwg_token })
		.then(({ data }) => {
			dispatch({
				type: Constants.GOOGLE_RESERVE_POST_SUCCESS,
				payload: data,
			});
		})
		.catch((error) => {
			dispatch({
				type: Constants.GOOGLE_RESERVE_POST_FAILURE,
				error: error.message,
			});
		});
};
export default {
	googleReservePost,
}