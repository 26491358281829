import React from "react";
import PritAppLogo from "assets/icons/pritapplogo.jpeg";
import { Typography } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { DeleteIcon } from "~/components/common/icons/Icons";
import { Divider } from "@material-ui/core";

const AppDownloadBar = ({ close }) => {
	return (
		<>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					padding: "6px 30px",
					alignItems: "center",
				}}>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						gap: "15px",
					}}>
					<DeleteIcon width={"15px"} onClick={close}></DeleteIcon>
					<img style={{ width: "50px", height: "50px", borderRadius: "18px" }} src={PritAppLogo} />
					<div>
						<Typography style={{ fontSize: "15px", fontWeight: "bold" }}>Prit</Typography>
						<Typography style={{ fontSize: "12px" }}>Aplicativo móvel</Typography>
					</div>
				</div>

				<div
					style={{ backgroundColor: "rgb(98,0,147)", padding: "5px 10px", borderRadius: "10px" }}
					onClick={() =>
						(window.location = navigator.userAgent.includes("Android")
							? "https://play.google.com/store/apps/details?id=app.prit"
							: "https://apps.apple.com/br/app/prit/id1466577543")
					}>
					<Typography style={{ color: "white" }}>Abrir</Typography>
				</div>
			</div>
			<div style={{ backgroundColor: "gray", width: "100%", height: "1px" }}></div>
		</>
	);
};

export default withRouter(AppDownloadBar);
