import { Container, Typography } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import React, { Fragment, useState } from "react";
import { withRouter } from "react-router-dom";
import StickyFooter from "~/components/common/stickyFooter/StickyFooter";
import MuiStylesTitleContainer from "~/components/common/titleContainer/MuiStylesTitleContainer";
import SpinnerLoading from "../spinnerLoading/SpinnerLoading";
import AppDownloadBar from "~/components/appDownloadBar/appDownloadBar";

const TitleContainer = (props) => {
	const { handleClick, title, subtitle, children, footer, loading, styles, hiddenAppDownloadBar } = props;
	const classes = MuiStylesTitleContainer();
	const [hideAppDownloadBar, setHideAppDownloadBar] = useState(localStorage.getItem("hideAppDownloadBar") || false);

	return (
		<Fragment>
			{/*{!hiddenAppDownloadBar && !hideAppDownloadBar && (
				<AppDownloadBar
					close={() => {
						localStorage.setItem("hideAppDownloadBar", true);
						setHideAppDownloadBar(true);
					}}
				/>
			)}*/}
			<SpinnerLoading loading={loading} />
			<Container className={classes.container}>
				{(handleClick || title || subtitle) && (
					<header className={classes.header} style={props.headerStyle}>
						{(handleClick || title) && (
							<div style={{ display: "flex", alignItems: "center" }}>
								{handleClick && (
									<div onClick={handleClick} style={{ cursor: "pointer" }}>
										<ArrowBackIosIcon />
									</div>
								)}
								{title && (
									<div style={{ width: "-webkit-fill-available" }}>
										<Typography variant="h3" className={classes.title}>
											{title}
										</Typography>
									</div>
								)}
							</div>
						)}
						{subtitle &&
							(subtitle && typeof subtitle === "string" ? (
								<Typography style={{ width: styles }} className={classes.subtitle}>
									{subtitle}
								</Typography>
							) : (
								subtitle
							))}
					</header>
				)}
				<div style={{ padding: "4%", height: "inherit", minHeight: "inherit" }}>{children}</div>
				{footer && <StickyFooter>{footer}</StickyFooter>}
			</Container>
		</Fragment>
	);
};
export default withRouter(TitleContainer);
