import { Grid, makeStyles } from "@material-ui/core";
import React from "react";

const styles = makeStyles((theme) => ({
	titleDiv: {
		display: "flex",
		alignItems: "center",
	},
	title: {
		fontWeight: "bold",
		marginRight: "10px",
	},
	counter: {
		color: "#3689ea",
		backgroundColor: "rgba(54,137,234, 0.1)",
		padding: "2px 8px",
		borderRadius: "13px",
	},
}));

const SectionDiv = (props) => {
	const classes = styles();
	return (
		<Grid item style={props.gridStyle} className={props.gridClassName}>
			<div style={props.titleDivStyle || null} className={props.titleDivClassName || classes.titleDiv}>
				<h2 style={props.titleStyle || null} className={props.titleClassName || classes.title}>
					{props.title}
				</h2>
				{(!!props.counter || props.counter === 0) && (
					<p style={props.counterStyle || null} className={props.counterClassName || classes.counter}>
						{props.counter}
					</p>
				)}
			</div>
			{props.children}
		</Grid>
	);
};
export default SectionDiv;
