import { Divider, IconButton, InputBase, Paper, Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import ColorButton from "~/components/common/colorButton/ColorButton";
import FeaturesSelected from "~/components/common/featuresSelected/FeaturesSelected";
import businessService from "~/services/businessService";
import { SearchIcon } from "~/components/common/icons/Icons";
import SpinnerLoading from "~/components/common/spinnerLoading/SpinnerLoading";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import ScheduleProgress from "~/components/common/scheduleProgress/ScheduleProgress";
import { useMixpanel } from "react-mixpanel-browser";
import PurpleCheckBox from "~/components/common/purpleCheckBox/purpleChackBox";
import { ArrowBackIos, KeyboardArrowDown, KeyboardArrowRight } from "@material-ui/icons";
import servicesService from "~/services/servicesService";
import TitleContainer from "~/components/common/titleContainer/TitleContainer";
import { useContext } from "react";
import { DialogBuilder } from "~/utils/DialogProvider";
import couponServices from "~/services/couponServices";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import businessAction from "~/store/actions/businessAction";
import WhatsAppButton from "~/components/common/whatsappButton/whatsappButton";

const ServiceScheduling = (props) => {
	const dialog = useContext(DialogBuilder);
	const mixpanel = useMixpanel();
	const classes = useStyles();
	const selectedCategorie = props?.location?.state?.categorie;
	const pet = props?.location?.state?.pet;
	const coupon = props?.location?.state?.coupon;

	const { business } = useSelector((store) => store.business);
	const { t } = useTranslation();
	const { user } = useSelector((store) => store.auth);

	const [selectedServices, setSelectedServices] = useState([]);
	const [services, setServices] = useState([]);
	const [servicesTree, setServicesTree] = useState([]);
	const [openedBranchs, setOpenedBranchs] = useState([]);
	const [search, setSearch] = useState("");
	const [loading, setLoading] = useState(false);
	const [treeView] = useState(false);
	const [currentLevel, setCurrentLevel] = useState([]);
	const { id } = useParams();
	const dispatch = useDispatch();

	useEffect(() => {
		setLoading(true);
		if (business?.id) {
			if (coupon) {
				couponServices
					.getBusinessServiceCoupons(
						business.id,
						selectedCategorie?.id || business.categories[0].id,
						coupon.coupon.coupon_id,
						pet?.featureNames[0],
						pet?.featureNames[1]
					)
					.then((res) => {
						let businessServices = res.data;
						if (businessServices) {
							setServices(businessServices);
							let tree = servicesService.buildServiceTree(businessServices, business.language);
							if (pet) {
								tree = servicesService.petPreselection(tree, pet);
							}
							setServicesTree(tree);
							setCurrentLevel(tree);
						}
						setLoading(false);
					})
					.catch((err) => {
						dialog
							.withTitle(t("Unable to load the services"))
							.withMessage(err.message)
							.withAction(() => props.history.goBack())
							.show();
						setLoading(false);
					});
			} else {
				businessService
					.getBusinessService(
						business.id,
						selectedCategorie?.id || business.categories[0].id,
						business.language,
						pet?.featureNames[0],
						pet?.featureNames[1]
					)
					.then((res) => {
						let businessServices = res.data;
						if (businessServices) {
							setServices(businessServices);
							let tree = servicesService.buildServiceTree(businessServices, business.language);
							if (pet) {
								tree = servicesService.petPreselection(tree, pet);
							}
							setServicesTree(tree);
							setCurrentLevel(tree);
						}
						setLoading(false);
					})
					.catch(
						({
							response: {
								data: { message },
							},
						}) => {
							dialog
								.withTitle(t("Unable to load the services"))
								.withMessage(message)
								.withAction(() => props.history.goBack())
								.show();
							setLoading(false);
						}
					);
			}
		} else {
			loadBusiness();
		}
	}, []);

	const loadSectors = async (categories) => {
		try {
			const sectors = await businessService.getSectors(categories.map((item) => item.id));
			localStorage.setItem("@prit-web/sectors", JSON.stringify(sectors.data));
			setLoading(false);
		} catch (err) {
			dialog
				.withMessage(t("Unable to load the sectors of this establishment"))
				.withButtonText(t("Try again"))
				.withAction(() => {
					loadSectors(categories);
				})
				.show();
			setLoading(false);
		}
	};

	const loadBusiness = () => {
		setLoading(true);
		businessService
			.getBusiness(id)
			.then((res) => {
				businessService
					.getBusinessService(id, res.data.categories[0].id, res.data.language,)
					.then((res) => {
						let businessServices = res.data;
						if (businessServices) {
							setServices(businessServices);
							let tree = servicesService.buildServiceTree(businessServices, res.data.language);
							if (pet) {
								tree = servicesService.petPreselection(tree, pet);
							}
							setServicesTree(tree);
							setCurrentLevel(tree);
						}
						setLoading(false);
					})
					.catch(
						({
							response: {
								data: { message },
							},
						}) => {
							dialog
								.withTitle(t("Unable to load the services"))
								.withMessage(message)
								.withAction(() => props.history.goBack())
								.show();
							setLoading(false);
						}
					);
				dispatch(businessAction.setBusiness(res.data));
				loadSectors(res.data.categories);
			})
			.catch((err) => {
				if (err?.response?.status === 401) {
					loadBusiness();
				} else {
					dialog
						.withMessage(err?.response?.data?.message || "Ops...")
						.withButtonText(t("Try again"))
						.withAction(() => {
							loadBusiness();
						})
						.show();
				}
				setLoading(false);
			});
	};

	const openBranch = (branch) => {
		setOpenedBranchs([...openedBranchs, branch.id]);
	};

	const closeBranch = (branch) => {
		setOpenedBranchs(
			openedBranchs.filter((openedBranch) => {
				return openedBranch !== branch.id;
			})
		);
	};

	const isOpen = (branch) => {
		return openedBranchs.includes(branch.id);
	};

	const renderBranch = (branch) => {
		let selectable = branch?.branchs?.length === 0;

		return (
			<>
				<div
					style={{ display: "flex", flexDirection: "column", height: "50px" }} /*key={branch.id}*/
					onClick={() => {
						if (selectable) {
							if (selectedServices.includes(branch.service)) {
								removeSelectedService(branch.service);
							} else {
								handleSelectService(branch.service);
							}
							mixpanel.identify(user?.phoneNumber ? user?.phoneNumber : user?.uid, {
								my_custom_prop: "foo",
							});
							mixpanel.track("web - selecionou serviço", {
								my_custom_prop: "foo",
							});
						} else {
							if (isOpen(branch)) {
								closeBranch(branch);
							} else {
								openBranch(branch);
							}
						}
					}}>
					<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
						<div key={branch?.id}>
							<h4 className={classes.nameList}>{branch?.name}</h4>
						</div>
						{selectable ? (
							<PurpleCheckBox checkedIcon={<CheckCircleIcon />} checked={selectedServices.includes(branch.service)} />
						) : isOpen(branch) ? (
							<KeyboardArrowDown />
						) : (
							<KeyboardArrowRight />
						)}
					</div>
				</div>
				{/* <Divider /> */}
				{isOpen(branch) &&
					branch.branchs.map((nextBranch, index) => {
						return (
							<div style={{ display: "flex", flexDirection: "row", flexBasis: 2, marginLeft: "10px" }}>
								<div>
									<div
										style={{
											height: "27px",
											width: "1px",
											backgroundColor: index === 0 || !isOpen(branch.branchs[index - 1]) ? "#ddd" : "transparent",
										}}
									/>

									<div
										style={{
											height: "1px",
											width: "25px",
											backgroundColor: "#ddd",
										}}
									/>
									{!(index === branch.branchs.length - 1) && !isOpen(nextBranch) && (
										<div
											style={{
												height: "23px",
												width: "1px",
												backgroundColor: "#ddd",
											}}
										/>
									)}
								</div>
								<div style={{ width: "10px" }} />

								<div style={{ flex: 1 }}>{renderBranch(nextBranch)}</div>
							</div>
						);
					})}
			</>
		);
	};

	const renderLevel = () => {
		let orderedCurrentLevel = currentLevel.slice();

		orderedCurrentLevel.sort((a, b) => {
			if (a.name > b.name) {
				return 1;
			}
			if (a.name < b.name) {
				return -1;
			}
			return 0;
		});
		return orderedCurrentLevel.map((branch) => {
			let selectable = branch?.branchs?.length === 0;
			return (
				<div
					className={classes.levelTileDiv} /*key={branch.id}*/
					onClick={() => {
						if (selectable) {
							if (selectedServices.includes(branch.service)) {
								removeSelectedService(branch.service);
							} else {
								handleSelectService(branch.service);
							}
							mixpanel.identify(user?.phoneNumber ? user?.phoneNumber : user?.uid, {
								my_custom_prop: "foo",
							});
							mixpanel.track("web - selecionou serviço", {
								my_custom_prop: "foo",
							});
						} else {
							setCurrentLevel(branch?.branchs);
						}
					}}>
					<div className={classes.levelTileAlign}>
						<div key={branch?.id}>
							<h4 className={classes.nameList}>{branch?.name}</h4>
						</div>
						{selectable ? (
							<PurpleCheckBox checkedIcon={<CheckCircleIcon />} checked={selectedServices.includes(branch.service)} />
						) : (
							<KeyboardArrowRight />
						)}
					</div>
					<Divider />
				</div>
			);
		});
	};

	const handleSchedule = () => {
		mixpanel.identify(user?.phoneNumber ? user?.phoneNumber : user?.uid, {
			my_custom_prop: "foo",
		});
		mixpanel.track("web - clicou continuar serviço", {
			my_custom_prop: "foo",
		});

		props.history.push(`/selecionar-data/${business.id}`, {
			services: selectedServices,
			// feature_items: feature_items,
			coupon: coupon,
		});
	};

	const footer = () => {
		return (
			<div className={classes.footerAlign} style={{ paddingTop: "20px" }}>
				<div style={{ display: "flex" }}>
					<div style={{ backgroundColor: "#450067" }} className={classes.countSelected}>
						{selectedServices.length}
					</div>
					<div className={classes.nameCountServices}>{t("Selected Services")}</div>
				</div>
				<div>
					<ColorButton
						disabled={selectedServices.length === 0}
						variant="contained"
						style={{ backgroundColor: "#450067", color: "white", opacity: selectedServices === 0 ? "50%" : "100%" }}
						className={classes.nameButton}
						onClick={handleSchedule}>
						{t("Next")}
					</ColorButton>
				</div>
			</div>
		);
	};

	const handleSelectService = (item) => {
		if (!selectedServices.includes(item)) {
			setSelectedServices([...selectedServices, item]);
		}
	};

	const removeSelectedService = (item) => {
		setSelectedServices(selectedServices.filter((element) => element.id !== item.id));
	};

	return (
		<Fragment>
			<SpinnerLoading loading={loading} />
			<TitleContainer
				title={
					<div className={classes.centerDiv}>
						<ArrowBackIos
							onClick={() => {
								if (business.categories.length > 1) {
									props.history.goBack();
								} else {
									props.history.push(`/business/${business.id}`);
								}
							}}
						/>
						<Typography className={classes.selectServicesLabel}>{t("Select Service")}</Typography>
					</div>
				}
				footer={footer()}>
				<ScheduleProgress progress={1}></ScheduleProgress>
				<div
					style={{
						display: "flex",
						overflowX: selectedServices?.length > 0 ? "scroll" : "none",
						overflowY: "hidden",
						marginTop: "15px",
					}}>
					<FeaturesSelected features={selectedServices} handleDelete={removeSelectedService} />
				</div>
				<div style={{ height: "10px" }}></div>
				{/*<Paper component="form" className={classes.root} style={{ marginTop: "5px", marginBottom: "15px" }}>
					<IconButton className={classes.iconButton} aria-label="search" disabled={true}>
						<SearchIcon />
					</IconButton>
					<InputBase
						style={{ fontSize: "14px" }}
						value={search}
						onChange={(e) => {
							setSearch(e.target.value);
						}}
						className={classes.inputSearch}
						placeholder={t("Name Service")}
						inputProps={{ "aria-label": "search service" }}
					/>
				</Paper>*/}
				<div>
					<Typography className={classes.levelTitle}>{t("Service")}</Typography>
					{search.length > 0
						? servicesTree
								.sort((a, b) => a.name.localeCompare(b.name))
								.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))
								.map((item) => <div key={item.id}>{renderBranch(item)}</div>)
						: services?.length > 0
						? treeView
							? servicesTree.map((item) => <div key={item.id}>{renderBranch(item)}</div>)
							: renderLevel()
						: !loading && <div className={classes.noServicesAvailble}>{t("No services available")}</div>}
				</div>
			</TitleContainer>
		</Fragment>
		
	);
};
export default withRouter(ServiceScheduling);
