import KeyboardCapslock from "@material-ui/icons/KeyboardCapslock";
import { ReactComponent as AccountSvg } from "~/assets/icons/account.svg";
import { ReactComponent as FacebookSvg } from "~/assets/icons/facebook.svg";
import { ReactComponent as InstagramSvg } from "~/assets/icons/instagram.svg";
import { ReactComponent as SiteSvg } from "~/assets/icons/site.svg";
import { ReactComponent as TwitterSvg } from "~/assets/icons/twitter.svg";
import { ReactComponent as BackPageSvg } from "~/assets/icons/backPage.svg";
import { ReactComponent as SearchIconSvg } from "~/assets/icons/searchIcon.svg";
import { ReactComponent as DeleteIconSvg } from "~/assets/icons/delete.svg";
import { ReactComponent as GooglePlayIconSvg } from "~/assets/icons/googlePlay.svg";
import { ReactComponent as AgendaIconSvg } from "~/assets/icons/appointments.svg";
import { ReactComponent as CreditCardIconSvg } from "~/assets/icons/creditCard.svg";
import { ReactComponent as ExitIconSvg } from "~/assets/icons/exit.svg";
import { ReactComponent as PritmanCheckSvg } from "~/assets/icons/pritman-check.svg";
import { ReactComponent as GiftIconSvg } from "~/assets/icons/gift_.svg";

export const KeyboardCapslockIcon = KeyboardCapslock;
export const AccountIcon = AccountSvg;
export const FacebookIcon = FacebookSvg;
export const InstagramIcon = InstagramSvg;
export const TwitterIcon = TwitterSvg;
export const SiteIcon = SiteSvg;
export const BackPage = BackPageSvg;
export const SearchIcon = SearchIconSvg;
export const DeleteIcon = DeleteIconSvg;
export const GooglePlayIcon = GooglePlayIconSvg;
export const AgendaIcon = AgendaIconSvg;
export const CreditCardIcon = CreditCardIconSvg;
export const ExitIcon = ExitIconSvg;
export const PritmanCheck = PritmanCheckSvg;
export const Gift = GiftIconSvg;

