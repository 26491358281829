import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Grid,
	Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import TitleDialog from "~/components/modalBookingScreen/TitleDialog";
import useStyles from "./style";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { withRouter } from "react-router";
import { FileCopy, FileCopyOutlined } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

const DialogPayCod = (props) => {
	const { onClose, pixCode, businessId } = props;
	const { t } = useTranslation();
	const classes = useStyles();

	const [hasCopied, sethasCopied] = useState(false);
	const [open, setOpen] = useState(false);

	return (
		<>
			<DialogTitle>
				{
					<TitleDialog
						onClose={() => {
							setOpen(true);
						}}
						message={""}
					/>
				}
			</DialogTitle>
			<div style={{ padding: "0 40px " }}>
				<Typography className={classes.titlePoli} style={{ fontSize: 15, color: "rgb(17 28 41)" }}>
					Pedido aguardando pagamento
				</Typography>
				<Typography className={classes.titlePoli} style={{ fontSize: 15, color: "grey", padding: "25px 0" }}>
					1. Copie o código abaixo
					<br></br>
					2. Pague via PIX Copia e Cola em qualquer aplicativo habilidado
				</Typography>

				<div
					style={{
						border: "2px dotted #dddddd",
						display: "flex",
						alignItems: "center",
					}}>
					<Typography style={{ margin: 11, color: "red" }}>{pixCode.substring(0, 20)}</Typography>
					<CopyToClipboard
						text={pixCode}
						onCopy={() => {
							sethasCopied(true);
						}}>
						<FileCopyOutlined style={{ color: "#3689ea" }}></FileCopyOutlined>
					</CopyToClipboard>
				</div>

				<Typography
					className={classes.titlePoli}
					style={{ fontSize: 15, color: "rgb(17 28 41 / 60%)", padding: "25px 0" }}>
					{t("Message payment 40")}
				</Typography>
				<Grid style={{ display: "flex", justifyContent: "center" }}>
					<CopyToClipboard text={pixCode}>
						<Button
							onClick={() => {
								sethasCopied(true);
							}}
							style={{
								width: "100%",
								marginRight: "1px",
								backgroundColor: "#3689ea",
								color: "#ffffff",
								marginBottom: "20px",
								fontSize: 12,
								fontWeight: "normal",
								textTransform: "none",
								borderRadius: 20,
								"&:hover": {
									backgroundColor: "#106ba3",
								},
							}}
							className={classes.root}>
							{hasCopied ? "Copiado!" : "Copiar código"}
						</Button>
					</CopyToClipboard>
				</Grid>
			</div>
			<Dialog
				onClose={() => setOpen(false)}
				open={open}
				PaperProps={{
					style: { borderRadius: 30 ,maxWidth: "320px",},
				}}>
				<DialogContent>
					<DialogContentText className={classes.text} style={{ fontSize: 15, color: "rgb(17 28 41 / 60%)" }}>
						{t("Message no received payment")}
					</DialogContentText>
				</DialogContent>
				<DialogActions className={classes.actions}>
					<Button
						style={{
							backgroundColor: "#3689ea",
							color: "#ffffff",
							fontSize: 12,
							borderRadius: 20,
						}}
						onClick={() => setOpen(false)}>
						{t("Wait")}
					</Button>
					<Button
						style={{
							backgroundColor: "#3689ea",
							color: "#ffffff",
							fontSize: 12,
							borderRadius: 20,
						}}
						onClick={() => {
							onClose();
							props.history.push(`/business/${businessId}`);
						}}>
						{t("Give up")}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default withRouter(DialogPayCod);
