import { Button, Grid, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { withRouter } from "react-router-dom";
import ErrorDialog from "~/components/common/dialog/index";
import TitleContainer from "~/components/common/titleContainer/TitleContainer";
import Utils from "~/helpers/Utils";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import { useMixpanel } from "react-mixpanel-browser";
import { sendMsgWavy, sendNumber, sendSmsTwilio } from "~/services/loginService";
import WhatsAppButton from "../common/whatsappButton/whatsappButton";
import { DialogBuilder } from "~/utils/DialogProvider";

function TelephoneComponent(props) {
	const mixpanel = useMixpanel();
	const classes = useStyles();

	const { t } = useTranslation();
	const [ddiIndex, setPhoneDdiIndex] = useState(0);
	const [phone, setPhone] = useState("");
	const [loading, setLoading] = useState(false);
	const [openError, setOpenError] = useState(false);
	const [messageError, setMessageError] = useState("Ops");

	const { goBackRoute, goBackRouteProps } = props.location.state;

	const dialog = useContext(DialogBuilder);

	const onClickGoBack = () => {
		props.history.goBack();
	};

	const handleOk = () => {
		window.location.reload();
	};

	const handleChangeType = (e) => {
		setPhoneDdiIndex(e.target.value);
	};

	const handleChange = (e) => {
		Utils.telephoneMask(e, Utils.ddis[ddiIndex].prefix);
	};

	const firebaseNumbers = [
		"+5516911111113",
		"+5516911111112",
		"+5516911111114",
		"+5516911111115",
		"+5511222222231",
		"+5511123456789",
		"+5511900000009",
		"+5571988888888",
		"+5511933330001",
	];

	const nextPage = (validateBy, phoneNumber, sessionInfo) => {
		if (
			!Utils.ddis[ddiIndex].ddi ||
			!phoneNumber ||
			Utils.ddis[ddiIndex].ddi.length === 0 ||
			phoneNumber.length === 0
		) {
			dialog
				.withMessage(`Existe algo errado com seu telefone, por favor verifique e tente novamente!`)
				.withAction(() => props.history.replace("/login", { goBackRoute, goBackRouteProps }))
				.show();
			return;
		}
		props.history.push("/login/code", {
			phone: phoneNumber,
			ddi: Utils.ddis[ddiIndex].ddi,
			validateBy,
			sessionInfo,
			goBackRoute,
			goBackRouteProps,
		});
	};

	const sendCode = () => {
		const removedMaskValue = phone.replace(/\D/g, "");

		let countryCodeValue;

		countryCodeValue = Utils.ddis[ddiIndex].ddi + removedMaskValue;

		let phoneNumber = countryCodeValue;

		setLoading(true);
		if (firebaseNumbers.includes(phoneNumber) || Utils.ddis[ddiIndex].ddi !== "+55") {
			sendNumber(phoneNumber)
				.then((res) => {
					nextPage("firebase", removedMaskValue, res.verificationId);
				})
				.catch((err) => {
					setMessageError(err?.response?.data?.message || err?.message);
					setOpenError(true);
					setLoading(false);
				});
		} else {
			sendMsgWavy(phoneNumber)
				.then((res) => {
					nextPage("wavy", removedMaskValue, null);
				})
				.catch((err) => {
					sendSmsTwilio(phoneNumber)
						.then((res) => {
							nextPage("twilio", removedMaskValue, null);
						})
						.catch((error) => {
							setMessageError(error?.response?.data?.message);
							setOpenError(true);
							setLoading(false);
						});
				});
		}
	};

	return (
		<>
			<TitleContainer
				loading={loading}
				handleClick={onClickGoBack}
				headerStyle={{ boxShadow: "none", paddingBottom: "0" }}
				//footer={footer(submitForm)}
			>
				<Grid container direction="column" spacing={3}>
					<Grid item xs={8}>
						<Typography className={classes.title}>
							<strong>{t("Login with Phone")}</strong>
						</Typography>
					</Grid>

					<Grid
						item
						style={{
							display: "inline-block",
							padding: "20,0,0,20",
							width: "100%",
							textAlign: "center",
							paddingBottom: 10,
						}}>
						<Select
							style={{
								width: "30%",
								paddingLeft: "5px",
								color: "#8f8e8e",
								borderBottom: "none",
							}}
							value={ddiIndex}
							label={ddiIndex}
							onChange={handleChangeType}>
							{Utils.ddis.map((e, i) => {
								return (
									<MenuItem value={i}>
										<img style={{ width: " 20px", paddingRight: "8px" }} src={e.img} alt={"Bandeira" + e.prefix} />
										{e.prefix + e.ddi}
									</MenuItem>
								);
							})}
						</Select>
						<TextField
							style={{ width: "70%", paddingLeft: "5px" }}
							name="telephone"
							placeholder={t("Enter your phone")}
							onChange={(e) => {
								setPhone(e.target.value);
							}}
							value={phone}
							inputProps={{
								onChange: (e) => {
									handleChange(e);
								},
							}}
							onKeyPress={(event) => {
								if (
									event.key === "Enter" &&
									phone.replace(/\D/g, "").length >= (Utils.ddis[ddiIndex].ddi == "+55" ? 11 : 8)
								) {
									event.preventDefault();
									mixpanel.track("web - digitou número avançar", {
										my_custom_prop: "foo",
									});
									sendCode();
								}
							}}
						/>
					</Grid>
				</Grid>
				<div style={{ marginTop: "5vh" }}>
					<Grid item container justify="center">
						<div className={classes.divMessage}>
							<Typography className={classes.message} style={{ fontSize: 15 }}>
								<strong>{t("We will send you a code via SMS to validate your phone number")}</strong>
							</Typography>
						</div>
					</Grid>
					<Grid justify="flex-end" alignItems="flex-end" item style={{ paddingTop: 10, textAlign: "center" }}>
						<Button
							id="send-number"
							type="submit"
							style={{
								backgroundColor:
									phone.replace(/\D/g, "").length < (Utils.ddis[ddiIndex].ddi == "+55" ? 11 : 8)
										? "#dddddd"
										: "#393762",
							}}
							disabled={phone.replace(/\D/g, "").length < (Utils.ddis[ddiIndex].ddi == "+55" ? 11 : 8)}
							className={classes.button}
							onClick={() => {
								mixpanel.track("web - digitou número avançar", {
									my_custom_prop: "foo",
								});
								sendCode();
							}}>
							{t("go")}
						</Button>
					</Grid>
				</div>
				<ErrorDialog onOpen={openError} message={messageError} onOk={handleOk} />
				<div id="recaptcha-container"></div>
			</TitleContainer>
			{/* <WhatsAppButton bottom={"30px"} /> */}
		</>
	);
}

export default withRouter(TelephoneComponent);
